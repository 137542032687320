import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config.json';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../shared/components/PageTitle';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { Formik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem, Box, FormControl, InputLabel, Grid, Button, Autocomplete } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Loader } from '../../shared/components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TableWrapper = styled.div`
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListVisibilityIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListVisibilityIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 3fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .filterDropdown {
        .closeIcon { 
            position: absolute;
            left: 450px;
            right: 0px;
            top: 20px;
            bottom: 0px;
        }
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
    }
   
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(0),
  },
  withoutLabel: {
    marginTop: theme.spacing(0),
  },
  greenButton: {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkgreen',
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'Type', numeric: false, label: 'Type' },
  { id: 'Description', numeric: false, label: 'Description' }
  // { id: 'ItemNo', numeric: false, label: 'Item No' }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function ManualSubmission() {
  const classes = useStyles();
  const token = config.TOKEN;
  const navigate = useNavigate();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [message, setMessage] = React.useState("");
  var [customers, setCustomers] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [itemPopup, setItemPopup] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [newCustomer, setNewCustomer] = React.useState({
    CustomerNo: "",
    name: "",
    businessNumber: "",
    Address: "",
    City: "",
    State: "",
    Country: "",
    Phone: "",
    Email: ""
  })
  const [newCustomerError, setNewCustomerError] = React.useState({ businessNumber: "", name: "", Phone: "" })
  const [itemData, setItemData] = React.useState({ Type: "", ItemNo: "", Description: "" });
  const [tableData, setTableData] = React.useState([]);
  const [itemErrors, setItemErrors] = useState({ Type: "", Description: "", ItemNo: "" });

  useEffect(() => {
    getCustomers();
  }, [])

  const getCustomers = () => {
    const MOBILESURETY_CUSTOMER_URL = config.API_URL + "/api/customerlist"
    axios.get(MOBILESURETY_CUSTOMER_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          customers = res.data.data
          setCustomers(customers)
        } else {
          setMessage(
            <div className="message">
              <div className="alert alert-danger" role="alert">
                "An error occured,try again later"
              </div>
            </div>)
          setTimeout(() => { setMessage("") }, 5000)
        }
      })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const itemTypeList = ["iPhone", "iPad", "Macbook", "Other"];

  const handleSave = () => {
    let formErrors = { Type: "", Description: "", ItemNo: "" };

    if (!itemData.Type) {
      formErrors.Type = "Required!";
    }

    if (!itemData.Description) {
      formErrors.Description = "Required!";
    }

    if (!itemData.ItemNo) {
      formErrors.ItemNo = "Required!";
    }

    if (formErrors.Type || formErrors.Description || formErrors.ItemNo) {
      setItemErrors(formErrors);
      return;
    }
    setTableData([...tableData, itemData]);
    setItemData({ Type: "", Description: "", ItemNo: "" })
    setItemErrors({ Type: "", Description: "", ItemNo: "" });
    setItemPopup(false);
  }

  const handleClose = () => {
    setItemData({ Type: "", Description: "", ItemNo: "" });
    setItemErrors({ Type: "", Description: "", ItemNo: "" })
    const itemPopup = false
    setItemPopup(itemPopup);
  }

  const handleDelete = (index) => {
    const updatedRows = tableData.filter((_, i) => i !== index);
    setTableData(updatedRows);
  }

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    console.log(e.target.files);

    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();

      reader.onload = () => {
        setItemData(prevData => ({ ...prevData, ItemNo: reader.result }));
        setItemErrors(prevErrors => ({ ...prevErrors, ItemNo: "" }));
        console.log(reader.result);
        e.target.value = "";
      }

      reader.onerror = (error) => {
        // setItemErrors({ ...itemErrors, ItemNo: 'Failed to read file' });
        setItemErrors(prevErrors => ({ ...prevErrors, ItemNo: "Failed to read file" }));
      };

      reader.readAsText(selectedFile);
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        OrderNumber: '', OrderDate: '', ExpShipDate: '', PONumber: '', businessNumber: '', name: '', Phone: ''
      }}
      validate={values => {
        const errors = {};

        if (!values.OrderNumber) {
          errors.OrderNumber = "Required!";
        }
        if (!values.OrderDate) {
          errors.OrderDate = "Required!";
        }
        if (!values.ExpShipDate) {
          errors.ExpShipDate = "Required!";
        }
        if (!values.PONumber) {
          errors.PONumber = "Required!";
        }
        if (!values.businessNumber) {
          errors.businessNumber = "Required!";
        }

        return errors;
      }}

      const handleMouseDownPwd={(event) => {
        event.preventDefault();
      }}

      onSubmit={(values, { setSubmitting }) => {
        const postData = {
          OrderDetails: {
            orderEntry: "",
            orderNumber: values.OrderNumber,
            customerNumber: "",
            // TEMPLATE: "",
            orderDate: values.OrderDate,
            expShipDate: values.ExpShipDate,
            deliverBy: values.DeliverBy,
            Items: tableData.map(item => ({
              type: item.Type,
              itemNo: "",
              description: item.Description,
              // comments: item.ItemNo.replace(/[\r,]/g, "").split("\n")
              comments: item.ItemNo.replace(/[\r/a]/g, "").replace(/,/g, "\n").split("\n") 
            }))
          },
          PoDetails: {
            poNumber: values.PONumber
          },
          CustomerDetails: {
            customerNo: "",
            customerName: values.name,
            businessRegNo: values.businessNumber,
            inactive: "",
            onHold: "",
            shortName: "",
            address: newCustomer.Address,
            city: newCustomer.City,
            state: newCustomer.State,
            country: newCustomer.Country,
            phone: newCustomer.Phone,
            email: newCustomer.Email,
            contactName: "",
            contactEmail: "",
            contactPhoneNumber: ""
          }
        }
        console.log(postData);

        // same api for add & edit
        const MOBILESURETY_URL = config.API_URL + "/api/dep/order/manual"
        axios.post(MOBILESURETY_URL, postData, {
          headers: {
            'Authorization': `Basic ${token}`
          }
        })
          .then((res) => {

            if (res.data.successCode === 1) {
              setMessage(
                <div className="message">
                  <div className="alert alert-success" role="alert">
                    "Manual Submission Added successfully!"
                  </div>
                </div>)
            } else {
              setMessage(
                <div className="message">
                  <div className="alert alert-danger" role="alert">
                    "An error occured,try again later"
                  </div>
                </div>)
            }
            setTimeout(() => { setMessage(""); navigate("/order/list") }, 5000)

          })

        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitForm,
        setValues,
        setFieldValue,
        setFieldTouched,
        setFieldError,
        isSubmitting,
      }) => {

        const handleAddCustomer = () => {
          let formErrors = { businessNumber: "", name: "", Phone: "" }

          if (!newCustomer.businessNumber) {
            formErrors.businessNumber = "Required!";
          }

          if (!newCustomer.name) {
            formErrors.name = "Required!";
          }

          if (!newCustomer.Phone) {
            formErrors.Phone = "Required!";
          }

          if (formErrors.businessNumber || formErrors.name || formErrors.Phone) {
            setNewCustomerError(formErrors);
            return;
          }

          const existingCustomer = customers.find((customer) => customer.name === newCustomer.name);

          if (existingCustomer) {
            if (existingCustomer.businessNumber !== newCustomer.businessNumber) {
              existingCustomer.businessNumber = newCustomer.businessNumber;
              setCustomers([...customers]);
            }
            setSelectedCustomer(existingCustomer);
          } else {
            setCustomers((prevCustomers) => [...prevCustomers, newCustomer]);
            setSelectedCustomer(newCustomer);
          }
          setFieldValue("name", newCustomer.name);
          setFieldValue("businessNumber", newCustomer.businessNumber);
          setOpenPopup(false);
        };

        const handleClosePopup = () => {
          setNewCustomerError({ businessNumber: "", name: "", Phone: "" });
          const openPopup = false
          setOpenPopup(openPopup);
          setNewCustomer({
            CustomerNo: "",
            name: "",
            businessNumber: "",
            Address: "",
            City: "",
            State: "",
            Country: "",
            Phone: "",
            Email: ""
          })
        }

        return (
          <form onSubmit={handleSubmit}>
            {message}
            <TableWrapper>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={6}>
                  <PageTitle>Manual Submission</PageTitle>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="text-right">

                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField className="filterDropdown" style={{ width: "80%" }} label="Order Number" name="OrderNumber" variant="standard" value={values.OrderNumber || ''} onChange={handleChange} onBlur={handleBlur} />
                  <div className="errMsg" style={{ marginTop: '4px' }}>{errors.OrderNumber && touched.OrderNumber && errors.OrderNumber}</div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField className="filterDropdown" style={{ width: "80%" }} label="Order Date" type="date" name="OrderDate" variant="standard" value={values.OrderDate || null} onChange={handleChange} onBlur={handleBlur} InputLabelProps={{ shrink: true }} />
                  <div className="errMsg" style={{ marginTop: '4px' }}>{errors.OrderDate && touched.OrderDate && errors.OrderDate}</div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField className="filterDropdown" style={{ width: "80%" }} label="Expected Ship Date" type="date" name="ExpShipDate" variant="standard" value={values.ExpShipDate || null} onChange={handleChange} onBlur={handleBlur} InputLabelProps={{ shrink: true }} />
                  <div className="errMsg" style={{ marginTop: '4px' }}>{errors.ExpShipDate && touched.ExpShipDate && errors.ExpShipDate}</div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField className="filterDropdown" style={{ width: "80%" }} label="PO Number" name="PONumber" variant="standard" value={values.PONumber || ""} onChange={handleChange} onBlur={handleBlur} />
                  <div className="errMsg" style={{ marginTop: '4px' }}>{errors.PONumber && touched.PONumber && errors.PONumber}</div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField className="filterDropdown" style={{ width: "80%" }} label="Deliver By" type="date" name='DeliverBy' variant="standard" value={values.DeliverBy || ""} onChange={handleChange} onBlur={handleBlur} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl style={{ width: "80%" }}>
                    <Autocomplete
                      options={customers}
                      getOptionLabel={(option) => `${option.name} (${option.businessNumber})`}
                      value={customers.find(customer => customer.name === values.name && customer.businessNumber === values.businessNumber) || null}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setFieldValue("name", newValue.name);
                          setFieldValue("businessNumber", newValue.businessNumber);
                          setSelectedCustomer(newValue);
                          setFieldError("name", "");
                          setFieldError("businessNumber", "");
                          setFieldTouched("name", false);
                          setFieldTouched("businessNumber", false);
                        } else {
                          setFieldValue("name", "");
                          setFieldValue("businessNumber", "");
                        }
                      }}
                      onBlur={() => setFieldTouched("businessNumber", true, true)}
                      renderInput={(params) => (
                        <TextField {...params} label="Customer" variant="standard" helperText="" />
                      )}
                    />
                    <div className="errMsg" style={{ marginTop: '4px' }}>{errors.businessNumber && touched.businessNumber && errors.businessNumber}</div>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'right', paddingRight: '100px' }}>
                  <Button variant="contained" color="primary" className="noShadow addBtn" onClick={() => setOpenPopup(true)}>Add Customer</Button>
                </Grid>
                <Dialog open={openPopup} onClose={handleClosePopup} closeonescape="true" fullWidth maxWidth="md">
                  <DialogTitle>
                    <div className="text-right">
                      <FontAwesomeIcon icon={faWindowClose} className="closeIcon" onClick={handleClosePopup} />
                    </div>
                    Add Customer
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField className="filterDropdown" style={{ width: "90%" }} label="Business Reg No" variant="standard" name="businessNumber" value={newCustomer.businessNumber} onChange={(e) => { setNewCustomer({ ...newCustomer, businessNumber: e.target.value }); setNewCustomerError({ ...newCustomerError, businessNumber: "" }) }} onBlur={handleBlur} />
                        <div className="errMsg" style={{ color: "red", marginTop: '4px' }}>{newCustomerError.businessNumber}</div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField className="filterDropdown" style={{ width: "90%" }} label="Customer Name" variant="standard" name="name" multiline value={newCustomer.name} onChange={(e) => { setNewCustomer({ ...newCustomer, name: e.target.value }); setNewCustomerError({ ...newCustomerError, name: "" }) }} onBlur={handleBlur} />
                        <div className="errMsg" style={{ color: "red", marginTop: '4px' }}>{newCustomerError.name}</div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField className="filterDropdown" style={{ width: "90%" }} item label="Address" variant="standard" name="Address" value={newCustomer.Address} onChange={(e) => setNewCustomer({ ...newCustomer, Address: e.target.value })} onBlur={handleBlur} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField className="filterDropdown" style={{ width: "90%" }} label="City" variant="standard" name="City" value={newCustomer.City} onChange={(e) => setNewCustomer({ ...newCustomer, City: e.target.value })} onBlur={handleBlur} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField className="filterDropdown" style={{ width: "90%" }} label="State" variant="standard" name="State" value={newCustomer.State} onChange={(e) => setNewCustomer({ ...newCustomer, State: e.target.value })} onBlur={handleBlur} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField className="filterDropdown" style={{ width: "90%" }} label="Country" variant="standard" name="Country" value={newCustomer.Country} onChange={(e) => setNewCustomer({ ...newCustomer, Country: e.target.value })} onBlur={handleBlur} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField className="filterDropdown" style={{ width: "90%" }} label="Phone" variant="standard" name="Phone" value={newCustomer.Phone} onChange={(e) => { setNewCustomer({ ...newCustomer, Phone: e.target.value }); setNewCustomerError({ ...newCustomerError, Phone: "" }) }} onBlur={handleBlur} />
                        <div className="errMsg" style={{ color: "red", marginTop: '4px' }}>{newCustomerError.Phone}</div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField className="filterDropdown" style={{ width: "90%" }} label="Email" variant="standard" name="Email" value={newCustomer.Email} onChange={(e) => setNewCustomer({ ...newCustomer, Email: e.target.value })} onBlur={handleBlur} />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Grid item xs={12} sm={12} md={12} className="text-right">
                      <Button variant="outlined" color="primary" className="noShadow cancelBtn" onClick={handleClosePopup}>
                        Cancel
                      </Button>&nbsp;&nbsp;
                      <Button variant="contained" color="primary" className="noShadow addBtn" onClick={handleAddCustomer}>
                        Save
                      </Button>
                    </Grid>
                  </DialogActions>
                </Dialog>
              </Grid>

              <Dialog open={itemPopup} onClose={handleClose} closeonescape="true" fullWidth maxWidth="sm">
                <DialogTitle>
                  <div className='text-right'>
                    <FontAwesomeIcon icon={faWindowClose} className="closeIcon" onClick={handleClose} />
                  </div>
                  Add Item
                </DialogTitle>
                <DialogContent>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={clsx(classes.withoutLabel, classes.textField)}>
                      <TextField style={{ width: "80%" }} className="filterDropdown" id="Type" select label="Item Type" variant="standard" name="Type" value={itemData.Type} onChange={(e) => { setItemData({ ...itemData, Type: e.target.value }); setItemErrors({ ...itemErrors, Type: "" }); }} >
                        {itemTypeList.map(type => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </TextField>
                      <div className="errMsg" style={{ color: "red", marginTop: '4px' }}>{itemErrors.Type}</div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField className="filterDropdown" label="Item Description" name="Description" value={itemData.Description} variant="standard" onChange={(e) => { setItemData({ ...itemData, Description: e.target.value }); setItemErrors({ ...itemErrors, Description: "" }); }} onBlur={handleBlur} style={{ width: "80%", marginTop: '16px' }} />
                    <div className="errMsg" style={{ color: "red", marginTop: '4px' }}>{itemErrors.Description}</div>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                    <TextField className="filterDropdown" label="Serial Number" multiline name="ItemNo" value={itemData.ItemNo} variant="standard" onChange={(e) => { setItemData(prevData => ({ ...prevData, ItemNo: e.target.value })); setItemErrors(prevErrors => ({ ...prevErrors, ItemNo: "" }));  }} onBlur={handleBlur} style={{ width: "80%", marginTop: "16px" }} />
                    <div className="errMsg" style={{ color: "red", marginTop: '4px' }}>{itemErrors.ItemNo}</div>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} style={{ textAlign: "right", paddingRight: "110px", marginTop: "15px" }}>
                    <input type="file" id="file-upload" onChange={handleFileUpload} style={{ display: 'none' }} />
                    <label htmlFor="file-upload">
                      <Button variant="contained" color="primary" className="noShadow addBtn" component="span">
                        Upload
                      </Button>
                    </label>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Grid item xs={12} sm={12} md={12} className='text-right'>
                    <Button variant="outlined" color="primary" className="noShadow cancelBtn" onClick={handleClose}>
                      Cancel
                    </Button>&nbsp;&nbsp;
                    <Button variant="contained" color="primary" className="noShadow addBtn" onClick={handleSave}>
                      Save
                    </Button>
                  </Grid>
                </DialogActions>
              </Dialog>

              <Dialog open={open} onClose={() => setOpen(false)} closeonescape="true" maxWidth="true" >
                <TableWrapper>
                  <DialogContent className="dialogStyle">
                    <div className={`${classes.root} materialTableStyle`}>
                      Do you want to submit the form?
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Grid container spacing={4} className="mt-1">
                      <Grid xs={12} sm={12} md={12} className="text-center">
                        <Button variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => setOpen(false)}>
                          No
                        </Button>&nbsp;&nbsp;
                        <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} onClick={() => { setOpen(false); submitForm(); }}>
                          Yes
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </TableWrapper>
              </Dialog>

              <TableWrapper>
                <div className={`${classes.root} materialTableStyle`}>
                  <TableContainer>
                    <Grid className="d-flex justify-content-between align-items-center" style={{ marginTop: '15px' }}>
                      <div className="d-flex justify-content-center flex-grow-1">
                        <PageTitle>List of Items</PageTitle>
                      </div>
                      <Grid item xs={12} sm={12} md={12}>
                        <FontAwesomeIcon
                          icon={faAdd}
                          className="closeIcon m-3"
                          style={{
                            borderRadius: "50%",
                            backgroundColor: "rgb(245, 147, 0)",
                            color: "white",
                            padding: "5px",
                            fontSize: "20px",
                            cursor: "pointer"
                          }}
                          onClick={() => setItemPopup(true)}
                        />
                      </Grid>
                    </Grid>

                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort} />
                      <TableBody>
                        {stableSort(tableData, getComparator(order, orderBy))
                          .map((tableData, index) => {
                            return (
                              <TableRow
                                hover
                                tabIndex={-1}
                                key={tableData.id}
                              >
                                <TableCell>
                                  {tableData.Type}
                                </TableCell>
                                <TableCell>
                                  {tableData.Description}
                                  <div className="actionListDeleteIconPosition">
                                    <Tooltip title="delete" aria-label="delete">
                                      <IconButton className="actionBtn" aria-label="delete" onClick={() => handleDelete(index)}>
                                        <DeleteIcon className="actionIcon" />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </TableCell>
                                {/* <TableCell>
                                  {tableData.ItemNo}
                                </TableCell> */}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </TableWrapper>
              <Grid container spacing={4} className="text-right mt-4">
                <Grid item xs={12} sm={12} md={12}>
                  <Button variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/order/list")}>
                    Cancel
                  </Button>&nbsp;&nbsp;
                  <Button type="button" variant="contained" color="primary" className="noShadow addBtn" onClick={() => {
                    if (Object.keys(errors).length === 0 && Object.keys(touched).length > 0) {
                      setOpen(true);
                    } else {
                      handleSubmit();
                    }
                  }}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </TableWrapper>
          </form>
        )
      }}
    </Formik >
  )
}
